import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Paper} from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import './styles.scss'
import {helpHandler} from "../../store/actions";
import CancelIcon from '@material-ui/icons/Cancel';


const useStyles = makeStyles((theme) => ({
  modal: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    border: 'none',
    backgroundColor: 'red',
    width: 500,

  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(1, 2, 2, 3),
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    paddingRight: 10,
    paddingBottom:20

  },
}));

export default function HelpModal(props) {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);
  // const {open, setOpen, state: {values, errors}, setState} = props
  // const {open, setOpen, loading, state, setState, newTerritory, storeHandler, addNewError,error=''} = props;
  // const {values, errors} = state;
  //
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  //
  const handleClose = () => {
    // setOpen(false);
  };

  useEffect(() => {

  }, [props.addNewError])

  const helpReducer = useSelector(state => state.helpReducer)
  const {open = false, data = []} = helpReducer;

  const dispatch = useDispatch()
  const helpClose = () => {
    dispatch(helpHandler({open: false, data: null}))
  }

  return (
    <Paper
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={'help-modal-container'}

      hidden={!open}
      onClose={handleClose}
      // closeAfterTransition
      // BackdropComponent={Backdrop}
      // BackdropProps={{timeout: 500,}}
      elevation={9}
    >
      <div>
        <div className='display-flex justify-content-end'>
          {/*<p  > x </p>*/}
          <CancelIcon onClick={helpClose} className='cursor-pointer m-0 ' style={{color:'red'}}/>
        </div>
        <div className={classes.paper}>
          {
            (data || []).map((item, index) => {
              let key = Object.keys(item)[0]
              let label = Object.keys(item)[1]

              return (
                <Paper elevation={2} className='display-flex   pl-10 pr-10 mt-10' style={{borderRadius:10,paddingBottom:15,paddingTop:15}}>
                  <p className='m-0 text-bold'>{item[label]}:</p>
                  <p className='pl-10 m-0' style={{color:'rgba(0,0,0,0.68)'}}>{item[key]}</p>
                </Paper>
              )
            })
          }

        </div>
      </div>
    </Paper>
  );
}
